/* Template Name: Pacto - Responsive Landing Template
   Author: Uniquecrew
   Email: support@shreethemes.in
   Website: http://www.shreethemes.in
   Version: 1.0.0
   Created: October 2020
   File Description: Main JS file of the template
*/

import "./unicons.js"
import feather from "feather-icons"
import $ from "jquery"

window.addEventListener('load', fn, false)

//  window.onload = function loader() {
function fn() {
  // Preloader
  if (document.getElementById('preloader')) {
    setTimeout(() => {
      document.getElementById('preloader').style.visibility = 'hidden';
      document.getElementById('preloader').style.opacity = '0';
    }, 350);
  }
  feather.replace();
  $('#isToggle').on("click", toggleMenu);
  // Menus
  activateMenu();
}

//Menu
// Toggle menu
function toggleMenu() {
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
    isOpen.style.display = "none";
  } else {
    isOpen.style.display = "block";
  }
};

//  //Menu Active
function activateMenu() {
  var menuItems = document.getElementsByClassName("sub-menu-item");
  if (menuItems) {

    var matchingMenuItem = null;
    for (var idx = 0; idx < menuItems.length; idx++) {
      if (menuItems[idx].href === window.location.href) {
        matchingMenuItem = menuItems[idx];
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active');
      var immediateParent = getClosest(matchingMenuItem, 'li');
      if (immediateParent) {
        immediateParent.classList.add('active');
      }

      var parent = getClosest(matchingMenuItem, '.parent-menu-item');
      if (parent) {
        parent.classList.add('active');
        var parentMenuitem = parent.querySelector('.menu-item');
        if (parentMenuitem) {
          parentMenuitem.classList.add('active');
        }
        var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
        if (parentOfParent) {
          parentOfParent.classList.add('active');
        }
      } else {
        var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
        if (parentOfParent) {
          parentOfParent.classList.add('active');
        }
      }
    }
  }
}

// Clickable Menu
if (document.getElementById("navigation")) {
  var elements = document.getElementById("navigation").getElementsByTagName("a");
  for (var i = 0, len = elements.length; i < len; i++) {
    elements[i].onclick = function (elem) {
      if (elem.target.getAttribute("href") === "javascript:void(0)") {
        var submenu = elem.target.nextElementSibling.nextElementSibling;
        submenu.classList.toggle('open');
      }
    }
  }
}

// Menu sticky
function windowScroll() {
  const navbar = document.getElementById("topnav");

  if (navbar != null) {
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }
  }
}

window.addEventListener('scroll', (ev) => {
  ev.preventDefault();
  windowScroll();
})

// back-to-top
var mybutton = document.getElementById("back-to-top");

window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (mybutton != null) {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
}

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
